import { defineStore } from 'pinia';
import { apiEgpmDictWeight } from "@/request/egpmMaterialApi";


export default defineStore('egpmMaterialStore', {
    state: () => ({
        spanKey: 'configCategory',// 需要合并的字段
        // localKey: 'weightConfigEgpm',// 缓存key
    }),
    actions: {
        // 计算某个字段的合并数
        calcSpan(list = [], key) {
            list = list.reverse();
            list = list.map((item, index) => {
                // 计算大类列合并数
                item.rowspan = 1;
                item.colspan = 1;
                const pre = list[index - 1];
                if (pre && pre[key] === item[key]) {
                    item.rowspan = pre.rowspan + 1;
                    pre.rowspan = 0;
                    pre.colspan = 0;
                }
                return item;
            });
            return list.reverse();
        },
        // 获取权重配置列表
        getWeightConfig(reportConfigId) {
            return new Promise((resolve, reject) => {
                // 先到缓存中拿
                // let config = localStorage.getItem(this.localKey);
                // if (config) {
                //     config = this.calcSpan(JSON.parse(config), this.spanKey);
                //     resolve(config);
                //     return;
                // }
                // 缓存中拿不到，请求接口
                apiEgpmDictWeight({ reportConfigId }).then(res => {
                    // localStorage.setItem(this.localKey, JSON.stringify(res.data || []));
                    resolve(this.calcSpan(res.data || [], this.spanKey));
                }).catch(err => {
                    reject(err);
                })
            })
        },
        // 保存权重配置到缓存
        // saveWeightConfig(config) {
        //     localStorage.setItem(this.localKey, JSON.stringify(config));
        // },
        // 恢复默认权重配置
        resetWeightConfig(reportConfigId) {
            return new Promise((resolve, reject) => {
                apiEgpmDictWeight({ reportConfigId }).then(res => {
                    resolve(this.calcSpan(res.data || [], this.spanKey));
                    // localStorage.setItem(this.localKey, JSON.stringify(res.data || []));
                }).catch(err => {
                    reject(err);
                })
            })
        },
    },
    persist: {
        enabled: true,// 刷新页面后是否缓存
        strategies: [
            {
                storage: localStorage
            }
        ]
    }
})