<template>
    <div class="container">
        <div class="row_box"><h1 style="font-size: 20px">全局配置</h1></div>
        <!-- KPI权重设置 -->
        <div class="row_box">
            <div class="tit"><SmallTitle tit="KPI权重设置" /></div>
            <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }" show-summary :summary-method="getSummaries" :span-method="mergeRows">
                <el-table-column prop="configCategory" label="大类" width="150" />
                <el-table-column prop="configName" label="统计值（得分）" />
                <el-table-column label="评分权重" width="150">
                    <template #default="scope">
                        <el-input type="number" v-model="scope.row.configValue"></el-input>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="submit_box">
            <el-button @click="reset">默认配置</el-button>
            <el-button type="primary" @click="saveConfig">保存配置</el-button>
        </div>
    </div>
</template>

<script setup>
import { ref, h } from "vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import useEgpmStore from "@/store/egpm-material";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { apiEgpmDictWeightSave } from "@/request/egpmMaterialApi";
import withLoading from "@/utils/loading";

const router = useRouter();
const route = useRoute();
const egpmStore = useEgpmStore();
const tableData = ref([]);
const reportId = route.query.reportId;

// 获取默认权重配置
async function requestWeight() {
    tableData.value = await egpmStore.getWeightConfig(reportId);
}

// 合计
function getSummaries() {
    // 计算configValue的总和
    let total = 0;
    tableData.value.forEach((item) => {
        total += Number(item.configValue);
    });
    return ["", "总计", h("div", { style: { color: total > 100 ? "red" : "" } }, total)];
}

// 合并
function mergeRows({ row, column }) {
    if (column.label === "大类") {
        return { rowspan: row.rowspan, colspan: row.colspan };
    } else {
        return { rowspan: 1, colspan: 1 };
    }
}

// 保存
async function saveConfig() {
    // 验证权重总和是否等于100
    let total = 0;
    tableData.value.forEach((item) => {
        total += Number(item.configValue);
    });
    if (total !== 100) {
        ElMessage.error("评分权重总计必须等于100");
        return;
    }
    // 保存到缓存中
    // egpmStore.saveWeightConfig(tableData.value);
    // 保存请求
    const { code } = await withLoading(apiEgpmDictWeightSave)({ config: tableData.value, reportConfigId: reportId });
    if (code !== "0") return;
    ElMessageBox.confirm("保存成功!", "提示", {
        confirmButtonText: "返回任务列表",
        cancelButtonText: "继续编辑",
    }).then(() => {
        router.replace({
            path: "/egpm",
            query: {
                reportId: route.query.reportId,
            },
        });
    });
}

// 重置
async function reset() {
    tableData.value = await egpmStore.resetWeightConfig(reportId);
}

(() => {
    requestWeight();
})();
</script>

<style lang="scss" scoped>
.container {
    padding: 0 40px 40px 40px;
    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;
        .tit {
            padding-bottom: 12px;
            border-bottom: 1px solid #f2f2f2;
            margin-bottom: 15px;
        }
        .el-select {
            width: 100%;
        }
        .table_form {
            table {
                width: 100%;
                border-collapse: collapse;

                th,
                td {
                    border: 1px solid #ccc;
                    text-align: center;
                    padding: 6px;
                    color: #333;

                    &.back {
                        background-color: #f2f2f2;
                    }

                    &.text_red {
                        color: red;
                    }
                    .el-input {
                        width: 120px;
                    }
                }

                th {
                    background-color: #f2f2f2;
                }
            }
        }
    }
    .submit_box {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
}
</style>
